export function commentForm() {
  return {
    commentOpen: false,
    form: {
      ['@turbo:submit-end']() {
        this.$refs['comment-form'].reset()
      },
    },
    trigger: {
      ['@click']() {
        this.commentOpen = true
      },
      ['x-show']() {
        return !this.commentOpen
      },
    },
    close: {
      ['@click']() {
        this.commentOpen = false
        this.$refs['comment-form'].reset()
      },
    },
  }
}
