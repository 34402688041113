export function blogSearch() {
  return {
    searchOpen: false,
    empty: true,
    blogSearch: {
      ['@keydown'](evt) {
        if (evt.keyCode !== 13) return

        evt.preventDefault()

        if (this.$refs.blog_search_input.value.trim() === '') return

        this.$refs.blog_search.submit()
      },
      ['@keyup'](evt) {
        this.empty = evt.target.value === ''
      }
    },
    triggerBlogSearchField: {
      ['@click']() {
        this.searchOpen = !this.searchOpen;
        if(this.searchOpen) {
          this.$nextTick(() => { setTimeout(() => {this.$refs.blog_search_input.focus() }, 300)})
        }
      },
    }
  }
}
