export function checkCounter() {
  return {
    checks: 0,
    now: false,
    lastMidnight: false,
    nextMidnight: false,
    secondsSinceLastMidnight: false,
    secondsUntilNextMidnight: false,
    interval: false,
    init() {
      this.now = new Date()
      this.lastMidnight = new Date(
        this.now.getFullYear(),
        this.now.getMonth(),
        this.now.getDate(),
        0,
        0,
        0
      )
      let miliSecondsSinceLastMidnight = Math.abs(this.now - this.lastMidnight)
      this.secondsSinceLastMidnight = miliSecondsSinceLastMidnight / 1000
      if (this.secondsSinceLastMidnight >= 86400) {
        this.checks = 0
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.checks += 360
          }, 1000)
        }
      } else {
        this.checks = Math.round(this.secondsSinceLastMidnight * 360)
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.checks += 360
          }, 1000)
        }
      }
    },
  }
}
