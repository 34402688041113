import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
// Import htmx and define htmx because of missing UMD wrapper.
import './initHtmx.js'
import 'htmx-ext-head-support'
import 'htmx-ext-preload'
import '@github/auto-complete-element'

import { anchorify } from './anchorify'
import { status } from './status'
import { commentForm } from './components/comment-form'
import { popoutMenu } from './components/popout-menu'
import { mobileMenu } from './components/mobile-menu'
import { supportArticle } from './components/support-article'
import { slider } from './components/slider'
import { contactForm } from './components/contact-form'
import { searchDomain } from './domains'
import { agencySlider } from './components/agency-slider'
import scrollPosition from './scrollPosition'
import { checkCounter } from './components/check-counter'
import { helpscoutBeaconId } from './initBeacon'
import { stageLinks } from './stageLinks'
import { blogSearch } from './components/blog-search'
import { copyToClipboard } from './components/copy-to-clipboard'

import { searchSupportCenter } from './searchSupportCenter'
import { accordion, accordionItem } from './components/accordion'

Alpine.plugin(collapse)
Alpine.plugin(focus)

Alpine.store('mobileMenu', {
  open: false,
  hidden: false,
  toggle() {
    this.open = !this.open
  },
})

Alpine.store('alert', {
  dismissed: false,
  removeAlert() {
    document.body.style = ''
    this.dismissed = true
  },
})

Alpine.store('info', {
  dismissed: false,
  removeAlert() {
    document.body.style = ''
    this.dismissed = true
  },
})

Alpine.directive('cyon-ajax', (el, { expression }, { effect }) => {
  const endpoint = expression
  effect(() => {
    fetch(`/_ajax/${endpoint}`)
      .then((res) => res.text())
      .then((text) => (el.innerHTML = text))
  })
})

Alpine.directive('cyon-copy-button', (el, _, { cleanup }) => {
  let store = el.innerHTML
  let timeoutRef = undefined
  let handleClick = () => {
    el.innerHTML = `<svg class="h-6 w-6 select-none" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
      </svg><span>Link kopiert</span>`
    window.navigator.clipboard.writeText(window.location.toString())

    timeoutRef = setTimeout(() => {
      el.innerHTML = store
    }, 5000)
  }

  el.addEventListener('click', handleClick)

  cleanup(() => {
    el.removeEventListener('click', handleClick)
    clearTimeout(timeoutRef)
  })
})

Alpine.directive(
  'cyon-ask-support',
  (el, { expression: subject, modifiers }, { cleanup }) => {
    const handler = (event) => {
      if (window.Beacon === undefined) return

      event.preventDefault()
      window.Beacon('open')

      if (modifiers.includes('email')) {
        window.Beacon('navigate', '/ask/message/')
        window.Beacon('prefill', { subject: subject })
      }
    }

    el.addEventListener('click', handler)

    cleanup(() => {
      el.removeEventListener('click', handler)
      if (window.Beacon === undefined) return
      window.Beacon('reset')
    })
  }
)

Alpine.directive(
  'cyon-track',
  (el, { expression, modifiers }, { evaluate, cleanup }) => {
    const extras = evaluate(expression)

    modifiers.forEach((modifier) => {
      const handelEvent = () => {
        window._paq.push(['trackEvent', modifier].concat(extras))
      }

      el.addEventListener(modifier, handelEvent)

      cleanup(() => el.removeEventListener(modifier, handelEvent))
    })
  }
)

Alpine.data('cyon_popout_menu', popoutMenu)
Alpine.data('cyon_mobile_menu', mobileMenu)
Alpine.data('cyon_status', status)
Alpine.data('cyon_agency_slider', agencySlider)
Alpine.data('cyon_maleware_check', checkCounter)
Alpine.data('cyon_slider', slider)
Alpine.data('cyon_support_article', supportArticle)
Alpine.data('cyon_contact_form', contactForm)
Alpine.data('cyon_comment_form', commentForm)
Alpine.data('cyon_blog_search', blogSearch)
Alpine.data('cyon_copy_to_clipboard', copyToClipboard)
Alpine.data('cyon_accordion', accordion)
Alpine.data('cyon_accordion_item', accordionItem)

window.cyon = {
  searchDomain,
  searchSupportCenter: async (query) => {
    return (await fetch(`/support/api/search.json?q=${query}`)).json()
  },
}

Alpine.start()

function adjustTopSpacer() {
  let mainNavHeader = document.getElementById('main_nav_header');
  let topSpacer = document.body;
  let headerHeight = mainNavHeader.offsetHeight;
  topSpacer.style.paddingTop = `${headerHeight}px`;
}

window.adjustTopSpacer = adjustTopSpacer;

document.addEventListener('DOMContentLoaded', () => {
  window.Beacon('init', helpscoutBeaconId);
  anchorify();
  adjustTopSpacer();
})

document.addEventListener('htmx:afterSwap', () => {
  anchorify();
  stageLinks();
  if (Alpine.store('alert').dismissed || Alpine.store('info').dismissed) {
    document.body.style = '';
  }
  scrollPosition.restore();
  window.Beacon('init', helpscoutBeaconId);
  adjustTopSpacer();
});

document.addEventListener('htmx:beforeSwap', () => {
  scrollPosition.save();
  window.Beacon('destroy');
  Alpine.store('mobileMenu').open = false;
});

document.addEventListener("DOMContentLoaded", function () {
  const searchInput = document.getElementById("support_center_search_input");
  const resultsList = document.getElementById("search-results");

  let activeIndex = -1;

  searchInput.addEventListener("keydown", function (event) {
      const results = resultsList.querySelectorAll("li");

      if (results.length === 0) return;

      switch (event.key) {
          case "ArrowDown":
              event.preventDefault();
              activeIndex = (activeIndex + 1) % results.length;
              updateActiveItem(results);
              break;

          case "ArrowUp":
              event.preventDefault();
              activeIndex = (activeIndex - 1 + results.length) % results.length;
              updateActiveItem(results);
              break;

          case "Enter":
              event.preventDefault();
              if (activeIndex >= 0) {
                  results[activeIndex].querySelector("a").click();
              }
              break;
      }
  });

  function updateActiveItem(results) {
      results.forEach((item, index) => {
          if (index === activeIndex) {
              item.classList.add("bg-cyon-orange-ultralight");
              item.setAttribute("aria-selected", "true");
          } else {
              item.classList.remove("bg-cyon-orange-ultralight");
              item.setAttribute("aria-selected", "false");
          }
      });
  }

  // Re-bind event listeners when HTMX updates the search results
  document.body.addEventListener("htmx:afterSwap", function () {
      activeIndex = -1; // Reset index
      updateActiveItem(resultsList.querySelectorAll("li"));
  });
});
