export function anchorify() {
  // source https://github.com/attacomsian/code-examples/blob/master/others/anchor-links/anchor-javascript.html
  //add anchor link to all headings
  document
    .querySelectorAll(
      '.prose-sup h2, .prose-sup h3, .prose-sup h4, .prose-sup .faq-accordion details'
    )
    .forEach((heading) => {
      //create id from heading text
      const id =
        heading.getAttribute('id') ||
        heading.innerText
          .toLowerCase()
          .replace(/[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '')
          .replace(/[\u00c4\u00e4]/g, 'ae')
          .replace(/[\u00d6\u00f6]/g, 'oe')
          .replace(/[\u00dc\u00fc]/g, 'ue')
          .replace(/ +/g, '-')

      //add id to heading
      heading.setAttribute('id', id)
      //append parent class to heading
      heading.classList.add('group')

      const copyButton = document.createElement('button')
      copyButton.className = 'flex items-center justify-center heading-link'
      copyButton.setAttribute('data-section-link', 'true')
      copyButton.innerHTML =
        '<svg class="w-6 h-6 text-cyon-grey-light opacity-0 group-hover:opacity-100 select-none transition-opacity duration-150" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"></path></svg>'
      copyButton.onclick = (e) => {
        let store = e.target.innerHTML
        e.target.innerHTML = `<svg class="h-6 w-6 text-cyon-leaf select-none" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
        </svg>`

        window.history.replaceState(null, document.title, '#' + id)
        window.navigator.clipboard.writeText(window.location)

        setTimeout(() => {
          e.target.innerHTML = store
        }, 1000)
      }
      //append button after heading text
      const existing = heading.querySelector('.heading-link')
      if (existing) {
        existing.parentElement.removeChild(existing)
      }
      heading.appendChild(copyButton)
    })

  //navigate to anchor if available
  if (window.location.hash.length > 0) {
    setTimeout(function () {
      const el = document.getElementById(
        decodeURIComponent(window.location.hash.substring(1))
      )
      if (el !== null) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }, 150)
  }
}
