export function popoutMenu() {
  return {
    open: false,
    trigger: {
      ['@click']() {
        this.open = !this.open
      },
    },
    close: {
      ['@click.away']() {
        if (this.open) {
          this.open = false
        }
      },
    },
    menu: {
      ['x-show']() {
        return this.open
      },
      ['x-transition:enter']: 'transition ease-out duration-100',
      ['x-transition:enter-start']: 'transform opacity-0 scale-95',
      ['x-transition:enter-end']: 'transform opacity-100 scale-100',
    },
  }
}
