export function mobileMenu() {
  return {
    get mobileOpen() {
      return this.$store.mobileMenu.open
    },
    get hidden() {
      return this.$store.hidden
    },
    lastScrollPosition: 0,
    init() {
      if (document.body.parentElement) {
        // document.body.parentElement.style.overflowY = 'auto'

        const h = document.body.parentElement
        this.lastScrollPosition = h.scrollTop
        // document.addEventListener('scroll', () => {
        //   if (h.scrollTop < 300) return
        //   if (this.lastScrollPosition < h.scrollTop) {
        //     this.$store.mobileMenu.hidden = true
        //   } else {
        //     this.$store.mobileMenu.hidden = false
        //   }

        //   this.lastScrollPosition = h.scrollTop
        // })
      }
    },
    trigger: {
      ['@click']() {
        this.$store.mobileMenu.toggle()
        if (this.mobileOpen) {
          if (document.body.parentElement) {
            // document.body.parentElement.style.overflowY = 'hidden'
          }
        } else {
          setTimeout(() => {
            if (document.body.parentElement) {
              // document.body.parentElement.style.overflowY = 'auto'
            }
          }, 200)
        }
      },
    },
  }
}
