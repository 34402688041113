export const helpscoutBaseUrl = 'https://beacon-v2.helpscout.net'
export const helpscoutBeaconId = '68175fbb-4316-4768-ae7e-16984a989c3e'

!(function (e, t, n) {
  function a() {
    var e = t.getElementsByTagName('script')[0],
      n = t.createElement('script')
    ;(n.type = 'text/javascript'),
      (n.async = !0),
      (n.src = helpscoutBaseUrl),
      e.parentNode.insertBefore(n, e)
  }

  if (
    ((e.Beacon = n =
      function (t, n, a) {
        e.Beacon.readyQueue.push({ method: t, options: n, data: a })
      }),
    (n.readyQueue = []),
    'complete' === t.readyState)
  )
    return a()
  e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1)
})(window, document, window.Beacon || function () {})
