export function accordion() {
  let listenerFn = null
  return {
    active: '',
    init() {
      const self = this
      listenerFn = () => {
        self.active = document.location.hash.substring(1)
      }
      listenerFn()
      this.$watch('active', (value, _oldval) => {
        if (value !== '') {
          let url = new URL(document.location.toString())
          url.hash = value
          history.replaceState(history.state, document.title, url)
        } else {
          history.replaceState(
            history.state,
            document.title,
            document.location.pathname
          )
        }
      })

      addEventListener('hashchange', listenerFn)
      addEventListener('popstate', listenerFn)
    },
    destroy() {
      removeEventListener('hashchange', listenerFn)
      removeEventListener('popstate', listenerFn)
    },
  }
}

export function accordionItem(id = null) {
  return {
    id: id ? id : this.$el.id,
    get expanded() {
      return this.active === this.id
    },
    set expanded(value) {
      this.active = value ? this.id : ''
    },
    toggle: {
      ['@click']() {
        this.expanded = !this.expanded
      },
    },
  }
}
