export function stageLinks () {
  Array.from(document.querySelectorAll('a.stageLink')).forEach((el) => {
    const href = el.getAttribute('href')
    if (!href || href[0] !== '#') return

    el.onclick = (evt) => {
      window.scrollIntoView
      const anchor = document.querySelector(href)
      if (!anchor) return

      evt.preventDefault()
      evt.stopPropagation()

      anchor.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => {
        window.location.hash = href
      }, 300)
    }
  })
}
