export function agencySlider() {
  return {
    accounts: 20,
    get webspace() {
      return 50 * this.accounts
    },
    get pricePerAccount() {
      if (this.accounts < 50) {
        // 1 - 49       CHF 10,00 | € 10 | $ 11 | £ 8
        return 10.0
      }
      if (this.accounts < 100) {
        // 50 - 99    CHF 9,00 | € 9 | $ 10 | £ 7
        return 9.0
      }
      if (this.accounts < 250) {
        // 100-249  CHF 8,00 | € 8 | $ 9 | £ 6
        return 8.0
      }
      if (this.accounts < 500) {
        // 250-499  CHF 7,00 | € 7 |  $ 8 | £ 5
        return 7.0
      }
      if (this.accounts < Infinity) {
        // > 500       CHF 6,00 | € 6 | $ 7 | £ 4
        return 6.0
      }
    },
    get pricePerAccountFormated() {
      return this.pricePerAccount.toLocaleString('de-CH', {
        minimumFractionDigits: 2,
      })
    },
    get totalPrice() {
      return this.pricePerAccount * this.accounts
    },
    interval: null,
    init() {
      this.calcSliderProgress()
      this.$watch('accounts', () => this.calcSliderProgress())
    },
    calcSliderProgress() {
      const slider = this.$refs.agencyRangeSlider
      const percent =
        ((this.accounts - slider.min) / (slider.max - slider.min)) * 100
      this.$nextTick(() =>
        slider.style.setProperty('--webkitProgressPercent', `${percent}%`)
      )
    },
    increment() {
      if (this.accounts >= 500) return
      this.accounts++
    },
    decrement() {
      if (this.accounts <= 20) return
      this.accounts--
    },
    clearButtonInterval() {
      clearInterval(this.interval)
      this.interval = null
    },
    triggerIncrementSlider: {
      ['@click']() {
        this.increment()
      },
      ['@mousedown']() {
        this.increment()
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.increment()
          }, 200)
        }
      },
      ['@touchstart']() {
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.increment()
          }, 200)
        }
      },
      ['@mouseup']() {
        this.clearButtonInterval()
      },
      ['@touchend']() {
        this.clearButtonInterval()
      },
    },
    triggerDecrementSlider: {
      ['@click']() {
        this.decrement()
      },
      ['@mousedown']() {
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.decrement()
          }, 200)
        }
      },
      ['@touchstart']() {
        if (!this.interval) {
          this.interval = setInterval(() => {
            this.decrement()
          }, 200)
        }
      },
      ['@mouseup']() {
        this.clearButtonInterval()
      },
      ['@touchend']() {
        this.clearButtonInterval()
      },
    },
  }
}
