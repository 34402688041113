import { debounce } from 'debounce'
import smoothscroll from 'smoothscroll-polyfill'

// Polyfill for platforms that don't support smooth-scroll (e.g. Safari)
smoothscroll.polyfill()

export function slider() {
  return {
    currentPage: 1,
    totalPages: 0,
    mouseDown: false,
    dragging: false,
    init() {
      this.totalPages = this.$refs.container.children.length
      this.$refs.container.addEventListener(
        'scroll',
        debounce(() => {
          const scrollPosition = this.$refs.container.scrollLeft
          const nextIndex = Array.from(this.$refs.container.children).findIndex(
            (el) => el.offsetLeft >= scrollPosition
          )
          this.currentPage = nextIndex + 1
        }),
        200
      )

      document.body.addEventListener('mouseup', (evt) => {
        this.mouseDown = false
        if (!this.dragging) return true
        evt.stopPropagation()
        evt.preventDefault()
        this.dragging = false
      })
    },
    cardOffset(i) {
      return (
        this.$refs.container.children[i - 1].offsetLeft -
        this.$refs.container.offsetLeft
      )
    },
    cardClick(i) {
      if (this.dragging) return
      this.navigateTo(i)
    },
    navigateTo(i) {
      this.currentPage = i
      this.$refs.container.scroll({
        left: this.cardOffset(i),
        behavior: 'smooth',
      })
    },
    container: {
      ['@mousedown'](evt) {
        this.mouseDown = { x: evt.clientX, y: evt.clientY }
      },
      ['@mousemove'](evt) {
        if (this.mouseDown && !this.dragging) {
          this.dragging = true
        }

        if (!this.dragging) return

        const dx = this.mouseDown.x - evt.clientX
        if (Math.abs(dx) > 100) {
          if (dx > 0 && this.currentPage < this.totalPages) {
            this.navigateTo(this.currentPage + 1)
          } else if (dx < 0 && this.currentPage > 1) {
            this.navigateTo(this.currentPage - 1)
          }

          this.mouseDown = { x: evt.clientX, y: evt.clientY }
        }
      },
    },
    previous: {
      ['@click']() {
        if (this.currentPage === 1) {
          this.navigateTo(this.totalPages)
        } else {
          this.navigateTo(this.currentPage - 1)
        }
      },
    },
    next: {
      ['@click']() {
        if (this.currentPage === this.totalPages) {
          this.navigateTo(1)
        } else {
          this.navigateTo(this.currentPage + 1)
        }
      },
    },
  }
}
