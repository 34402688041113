import { debounce } from 'debounce'

export function supportArticle() {
  return {
    open: false,
    collapsable: false,
    currentFocusedId: null,
    init() {
      this.collapsable =
        this.$refs.toc.dataset.collapsable === 'true' ? true : false
      this.currentFocusedId = window.location.hash?.replace('#', '')

      document.addEventListener(
        'scroll',
        debounce(() => {
          const rootEl = document.querySelector('.sup-article')
          if (!rootEl) return
          const h2s = Array.from(rootEl.querySelectorAll('h2'))
          let foundTop = 0
          let foundIndex = h2s.findIndex((h2) => {
            foundTop = h2.getBoundingClientRect().top
            return foundTop > 0
          })

          if (foundTop > 100 && foundIndex > 0) {
            foundIndex = foundIndex - 1
          }

          if (foundIndex === -1) {
            foundIndex = h2s.length - 1
          }

          this.currentFocusedId = h2s[foundIndex].id
        }, 200)
      )
    },
    navigate(id) {
      this.currentFocusedId = id
      document
        .getElementById(id)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      setTimeout(() => {
        window.location.hash = '#' + id
      }, 200)
    },
  }
}
