import {
  addDays,
  getDay,
  nextMonday,
  setHours,
  setMinutes,
  setSeconds,
  differenceInMinutes,
  getHours,
  isBefore,
  differenceInCalendarDays,
  getMonth,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export function contactForm() {
  return {
    selected: 'email',
    emailAvailable: true,
    chatAvailable: false,
    phoneAvailable: true,
    textEmail: '',
    textChat: '',
    textPhone: '',
    init() {
      const updateStati = () => {
        const now = utcToZonedTime(Date.now(), 'Europe/Zurich')
        let nextAvailable = null

        if (itIsChrisamsTime(now)) {
          nextAvailable = availableAfterChrisams(now)
        } else if (itIsTheWeekend(now)) {
          nextAvailable = availableNextMonday(now)
        } else if (getHours(now) < 9) {
          nextAvailable = availableInTheMorning(now)
        } else if (getHours(now) > 16 && getDay(now) !== 5) {
          nextAvailable = availableInTheMorning(addDays(now, 1))
        } else if (getHours(now) > 16 && getDay(now) === 5) {
          nextAvailable = availableNextMonday(now)
        } else if (itIsLunchBreak(now)) {
          nextAvailable = availableAfterLunchBreak(now)
        }

        this.phoneAvailable = !nextAvailable
        this.chatAvailable = !nextAvailable
        this.emailAvailable = !nextAvailable

        if (nextAvailable) {
          const diffInMinutes = differenceInMinutes(nextAvailable, now)
          this.textPhone = `Öffnet in ${Math.floor(diffInMinutes / 60)}h ${
            diffInMinutes % 60
          }m`
          this.textChat = `Öffnet in ${Math.floor(diffInMinutes / 60)}h ${
            diffInMinutes % 60
          }m`
          this.textEmail = `Öffnet in ${Math.floor(diffInMinutes / 60)}h ${
            diffInMinutes % 60
          }m`
        } else {
          this.textChat = 'Verfügbar bis 17:00 Uhr'
          this.textPhone = 'Verfügbar bis 17:00 Uhr'
          this.textEmail = 'Verfügbar bis 17:00 Uhr'
        }

        if (
          differenceInCalendarDays(
            now,
            utcToZonedTime(new Date('2023-05-05'), 'Europe/Zurich')
          ) === 0
        ) {
          //this.textChat = 'Heute verfügbar ab 15:30 bis 17:00 Uhr'
          this.textPhone = 'Heute verfügbar bis 16:30 Uhr'
          // this.textEmail = 'Heute verfügbar ab 15:30 bis 17:00 Uhr'
          if (now.getHours() >= 11) {
            this.phoneAvailable = false
            this.chatAvailable = false
          }
          return
        }
      }

      updateStati()
      setInterval(updateStati, 10000)
    },
    select(active) {
      this.selected = active
    },
  }
}

function itIsChrisamsTime(now) {
  return getMonth(now) === 11 && getDay(now) >= 23 && getDay(now) <= 25
}

function availableAfterChrisams(now) {
  const afterChrisams = utcToZonedTime(
    new Date(`${now.getFullYear()}-12-27`),
    'Europe/Zurich'
  )
  return setSeconds(setMinutes(setHours(afterChrisams, 9), 0), 0)
}

function itIsTheWeekend(now) {
  return [0, 6].includes(getDay(now))
}

function itIsLunchBreak(now) {
  return getHours(now) > 11 && isBefore(now, setMinutes(setHours(now, 13), 30))
}

function availableNextMonday(now) {
  return setSeconds(setMinutes(setHours(nextMonday(now), 9), 0), 0)
}

function availableInTheMorning(now) {
  return setSeconds(setMinutes(setHours(now, 9), 0), 0)
}

function availableAfterLunchBreak(now) {
  return setSeconds(setMinutes(setHours(now, 13), 30), 0)
}
