const STATUS_SCHEDULED = 0
const STATUS_INVESTIGATING = 1
const STATUS_IDENTIFIED = 2
const STATUS_WATCHING = 3
const STATUS_FIXED = 4

const SCHEDULED_UPCOMING = 0
const SCHEDULED_IN_PROGRESS = 1
const SCHEDULED_COMLEATED = 2

const THREE_DAYS = 259200000

function getMax(arr, prop) {
  let max = 1
  for (let i = 0; i < arr.length; i++) {
    if (!max || parseInt(arr[i][prop]) > max) {
      max = arr[i][prop]
    }
  }
  return max
}

const baseURL = 'https://status.cyon.info/api/v1'

export function status() {
  return {
    threatLevel: 0,
    maintenanceItems: [],
    incidentItems: [],
    statusLevel: 0,
    async init() {
      //fetch incidents
      const { data: incidents } = await fetch(
        baseURL + '/incidents?sort=id&order=desc'
      ).then((res) => res.json())

      const hasOpenedIncidents = incidents.find((incident) => {
        return (
          incident.status === STATUS_INVESTIGATING ||
          incident.status === STATUS_IDENTIFIED ||
          incident.status === STATUS_WATCHING
        )
      })

      if (hasOpenedIncidents) {
        this.threatLevel = 1
      }

      let scheduledTask = await this.getScheduledTasks()
      this.maintenanceItems = scheduledTask.filter((planned) => {
        switch (planned.status) {
          case SCHEDULED_COMLEATED:
            let dateDiffCompleated =
              Date.now() - Date.parse(planned.updated_at?.replace(' ', 'T'))
            return dateDiffCompleated < THREE_DAYS
          default:
            return true
        }
      })

      const filteredIncidents = incidents.filter((incident) => {
        const date = incident.updated_at || incident.created_at
        let dateDiff = Date.now() - Date.parse(date.replace(' ', 'T'))

        return !(dateDiff > THREE_DAYS && incident.status === STATUS_FIXED)
      })

      this.incidentItems = filteredIncidents

      this.statusLevel = await this.getStatusLevel()
    },
    async getScheduledTasks() {
      let res = await fetch(baseURL + '/schedules?sort=updated_at')
      let { data } = await res.json()
      return data
    },
    async getStatusLevel() {
      //fetch statusLevel
      const { data: statusLevels } = await fetch(
        baseURL + '/components?sort=id&order=desc'
      )
        .then((res) => res.json())
        .catch((err) => {
          console.error(err)
        })

      //use the highest current status level to display
      return getMax(statusLevels, 'status')
    },
  }
}
