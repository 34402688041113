export function copyToClipboard() {
  return {
    trigger: {
      ['@click'](e) {
        if (e.target.parentElement.copyText) {
          this.$clipboard(e.target.parentElement.copyText)
        }
      },
    },
  }
}
