function save() {
  document.body.dataset.scrollPosition = JSON.stringify([
    window.scrollX,
    window.scrollY,
  ])
}

function restore() {
  const scrollPosition = JSON.parse(
    document.body.dataset.scrollPosition || '[]'
  )
  if (scrollPosition.length > 0) {
    window.scrollTo(...scrollPosition)
  }
  delete document.body.dataset.scrollPosition
}

export default { save, restore }
